@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Inter', 'sans-serif';
  background: #f2f3f5;
}

.app {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-rows: auto;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle On Hover */

::-webkit-scrollbar-track:hover {
  background: #555;
}

label{
  color: #000 !important;
}
