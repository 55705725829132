.simple-chart {
  background: #fff;
  padding: 40px 0px;
}
.simple-charttwo {
  background: #fff;
  /* padding: 40px 0px; */
}
.Account-Actives-purshases {
  padding: 24px 0px 42px 0px;
}
.chat-bg {
  background: #fff;
  border-radius: 5px;
  padding: 15px 5px;
}
/*member*/
.add-budget-anchor a {
  color: "#A1A1A1";
  text-decoration: underline;
}
/* Workflow css  */
.btn-link-div {
  display: flex;
  gap: 29px;
  justify-content: center;
  align-items: center;
}
.btn-shop-for-workflow {
  border: 1px solid #d7f62f;
  background: #d7f62f;
  padding: 13px 36px;
  border-radius: 3px;
}
.workflow-Approval {
  background: #29c01c;
  border: 1px solid #29c01c;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval:hover {
  background: #29c01c;
}
button.deny-btn {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  background: none;
}
.btn-shop-for-workflow:hover {
  background: #d7f62f;
  color: #000;
}
.workflow-Approval-pending {
  background: red;
  border: 1px solid red;
  padding: 4px 34px;
  border-radius: 15px;
  cursor: pointer;
}
.workflow-Approval-pending:hover {
  background-color: red;
  color: #fff;
}
